.title-analysis-card {
	color: rgb(73, 73, 73);
	font-family: 'Poppins', sans-serif;
	font-size: 2em;
	font-weight: 200;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 25px;
}

.title-analysis-card-tags {
	font-family: 'Poppins', sans-serif;
	font-size: 1.4em;
	font-weight: 200;
	cursor: default;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 8px;
}

.button-base {
	transition: background-color 0.3s ease;
}

.button-base:hover {
	background-color: rgba(0, 0, 0, 0.1); /* Adjust the hover background color as needed */
}

.button-base:hover .hover-grid {
	background-color: rgba(0, 0, 0, 0.1) !important; /* Adjust the hover background color for the Grid as needed */
}
